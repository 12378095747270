export default {
  en: {
    contactUs: "Contact us",
    whoWeAre: "Who we are",
    haveQuestion: "Have a question?",
    reachOut: "Reach out to us!",
    whatWeDo: "What we do",
    whereWeOperate: "Where we operate",
    home: "Home",
    ourValues: "Our Values",
    moreInfo: "MORE INFO",
    catalogue: "CATALOGUE",
    learnMore: "Learn More",
    readMore: "Read More",
    services: "services",
    backToServices: "Back to Services",
    aboutUs: "about us",
    ourApproach: "Our Approach",
    ourVision: "Our Vision",
    ourMission: "Our Mission",
    getInTouch: "Get in touch",
    callUsOn: "Call us at",
    emailUsOn: "Email us at",
    weAreHere: "Find us at,",
    followUsOn: "Follow us on",
    search: "Search",
    searchSubtitle: "Find what you need",
    searchExample: 'Example "Steelmaking"',
    searchResult: "Search Results",
    noResult: "0 results found",
    products: "products",
  },
};
