export default {
  getters: {
    productType(state) {
      return state.productType;
    },
    productTypeDetail(state) {
      return state.productTypeDetail;
    },
  },
};
