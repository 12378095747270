import http from "/src/httpRequest.js";
export default {
  actions: {
    async getServices(context) {
      const response = await http.get("whatWeDo");
      context.commit("setServices", response.data);
    },
    async getServicesDetail(context, payload) {
      const response = await http.get(`services/${payload}`);
      context.commit("setServicesDetail", response.data);
    },
    async clearServiceDetail(context) {
      context.commit("clearServiceDetail", null);
    },
  },
};
