<template>
  <div class="my-12 lg:my-24 grid grid-cols-2 layout-padding order-last">
    <div class="col-span-2 lg:col-span-1 lg:hidden flex">
      <div class="service-detail-image-skeleton">
        <div class="home-values-border"></div>
      </div>
    </div>
    <div
      class="
        col-span-2
        lg:col-span-1
        flex flex-col
        justify-center
        lg:mb-0
        my-12
      "
    >
      <div class="relative">
        <div class="page-title mb-6 lg:mb-16"></div>
        <div class="services-page-underlay-two uppercase">
          {{ content.services }}
        </div>
      </div>
      <div class="title-skeleton"></div>
      <div class="service-detail-text-skelton"></div>
    </div>
    <div class="col-span-2 lg:col-span-1 lg:flex hidden">
      <div class="service-detail-image-skeleton">
        <div class="home-values-border"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["content"],
};
</script>

<style scoped>
.service-detail-image-skeleton {
  border-radius: 10px;
  min-height: 550px;
  width: 100%;
  background: #d9d9d922;
  background-image: linear-gradient(
    to right,
    #d9d9d922 0%,
    #d9d9d922 20%,
    #d9d9d922 40%,
    #d9d9d922 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 100vh;
  animation: shimmer 2s linear infinite;
}
.title-skeleton {
  min-height: 30px;
  margin-top: 50px;
  border-radius: 10px;
  width: 90%;
  background: #d9d9d922;
  background-image: linear-gradient(
    to right,
    #d9d9d922 0%,
    #d9d9d922 20%,
    #d9d9d922 40%,
    #d9d9d922 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 100vh;
  animation: shimmer 2s linear infinite;
}
.service-detail-text-skelton {
  min-height: 200px;
  margin-top: 20px;
  border-radius: 10px;

  width: 90%;
  background: #d9d9d922;
  background-image: linear-gradient(
    to right,
    #d9d9d922 0%,
    #d9d9d922 20%,
    #d9d9d922 40%,
    #d9d9d922 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 100vh;
  animation: shimmer 2s linear infinite;
}
@keyframes shimmer {
  0% {
    background-position: -100vw 0;
  }
  100% {
    background-position: 100vw 0;
  }
}
</style>