<template>
  <div
    class="
      grid
      home-values-container
      mt-16
      mb-32
      lg:mb-48
      grid-cols-5
      layout-padding
    "
  >
    <div class="col-span-5 lg:col-span-2">
      <div
        class="home-values-image"
        :style="`background: url(${values[0].image})`"
      >
        <div class="values-overlay p-8">
          <div class="home-values-border"></div>
        </div>
      </div>
    </div>
    <div class="col-span-5 lg:col-span-3 flex flex-col justify-center">
      <div class="lg:pl-32 pl-0">
        <div class="home-values-title">{{ content.ourValues }}</div>
        <div class="home-values-text mt-8">
          {{ values[0].description }}
        </div>
        <div class="home-values-rectangles">
          <div class="rectangle-shape"></div>
          <div class="rectangle-shape mx-4"></div>
          <div class="rectangle-shape"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["values"],
  inject: ["content"],
};
</script>