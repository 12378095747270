<template>
  <div class="search-page layout-padding my-12 lg:my-24">
    <div class="grid grid-cols-1">
      <div class="col-span-1 flex items-center relative">
        <div class="page-title">{{ content.search }}</div>
        <div class="relative w-full">
          <input
            class="search-page-input w-full"
            :placeholder="content.searchExample"
            v-model="keyword"
            @keyup="searchWithEnter"
          />
          <div class="search-btn">
            <SearchIconDark class="cursor-pointer" @click="startSearching" />
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="search-result-title mt-24">{{ content.searchResult }}</div>
      <div class="search-subtitle mt-10">{{ noResult }}</div>
      <div class="result-container">
        <div
          v-for="result in results"
          :key="result.key"
          class="flex my-12 search-result cursor-pointer"
          :class="{ 'result-border': results.length > 0 }"
          @click="gotoPage(result.page)"
        >
          <img
            :src="`/static/images/${routes[result.page]}-search.jpg`"
            class="search-result-image"
          />
          <div class="flex flex-col px-8 my-8">
            <div class="result-title">
              {{ routes[result.page] }}
            </div>
            <div class="result-key">
              {{ result.key }}
            </div>
            <div
              class="result-content cursor-pointer"
              v-html="result.result"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TheFooter />
</template>

<script>
import SearchIconDark from "/src/assets/Icons/SearchIcon-dark.vue";
export default {
  data() {
    return {
      keyword: "",
      results: [],
      routes: ["about", "services", "operations"],
      noResult: "",
    };
  },
  created() {
    document.getElementById("main-header").classList.add("bg-primary");
  },
  computed: {
    allData() {
      let about = this.$store.getters["whoWeAre/whoWeAre"];
      let services = this.$store.getters["services/services"];
      let operations = this.$store.getters["operations/operations"];
      return [about, services, operations];
    },
    allowSearch() {
      return !!this.allData[0] && !!this.allData[1] && !!this.allData[2];
    },
  },
  beforeUnmount() {
    document.getElementById("main-header").classList.remove("bg-primary");
  },
  watch: {
    allowSearch() {
      if (this.allowSearch != false) {
        this.startSearching();
      }
    },
  },
  mounted() {
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
      if (this.allowSearch != false) {
        this.startSearching();
      }
    }
  },
  methods: {
    gotoPage(page) {
      this.$router.push(this.routes[page]);
    },
    searchWithEnter(event) {
      if (event.key === "Enter" || event.keyCode === 13) {
        this.startSearching();
      }
    },
    startSearching() {
      if (this.keyword.length >= 3 && this.allowSearch != false) {
        this.results = this.searchFor(this.keyword);
      }
    },
    searchFor(toSearch) {
      let results = [];
      this.results = [];
      this.noResult = null;
      for (let i = 0; i < this.allData.length; i++) {
        this.allData[i].filter(function (obj) {
          return Object.keys(obj).some(function (key) {
            let result = obj[key].toString();
            if (result.toLowerCase().includes(toSearch.toLowerCase())) {
              if (key == "image") {
                return null;
              } else {
                let boldResult = result
                  .toLowerCase()
                  .replaceAll(
                    toSearch.toLowerCase(),
                    `<span class='dark-bold-text'>${toSearch}</span >`
                  );
                results.push({
                  page: i,
                  result: boldResult,
                  key: key,
                  obj: obj,
                });
              }
            }
          });
        });
      }
      if (results.length == 0) {
        this.noResult = this.content.noResult;
      }
      return results;
    },
  },
  components: {
    SearchIconDark,
  },
  inject: ["content"],
};
</script>