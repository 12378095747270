export default {
  mutations: {
    setHomeSlider(state, payload) {
      state.homeSlider = payload;
    },
    setHome(state, payload) {
      state.home = payload;
    },
  },
};
