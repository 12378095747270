export default {
  getters: {
    services(state) {
      return state.services;
    },
    servicesDetail(state) {
      return state.servicesDetail;
    },
  },
};
