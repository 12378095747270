import http from "/src/httpRequest.js";
export default {
  actions: {
    async getProductType(context) {
      const response = await http.get("productType");
      context.commit("setProductType", response.data);
    },
    async getProductTypeDetail(context, payload) {
      const response = await http.get(`productType/${payload}`);
      context.commit("setProductTypeDetail", response.data);
    },
    async clearProductTypeDetail(context) {
      context.commit("clearProductTypeDetail", null);
    },
  },
};
