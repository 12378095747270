export default {
  mutations: {
    setProducts(state, payload) {
      state.products = payload;
    },
    setProductsDetail(state, payload) {
      state.productsDetail = payload;
    },
    clearProductsDetail(state, payload) {
      state.productsDetail = payload;
    },
  },
};
