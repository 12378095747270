<template>
  <teleport to="body">
    <div class="footer-contact-container layout-padding" v-if="withContact">
      <div class="footer-contact">
        <div class="footer-contact-title">
          {{ content.haveQuestion }}
          <span style="font-weight: 700" class="footer-contact-subtitle">
            {{ content.reachOut }}</span
          >
        </div>
        <router-link to="/contact">
          <button class="btn-outline mb-0">
            <div class="flex items-center uppercase">
              {{ content.contactUs }}
              <img src="/static/images/arrow.svg" class="btn-arrow" />
            </div></button
        ></router-link>
      </div>
    </div>

    <footer>
      Copyright {{ new Date().getFullYear() }} - Blesai Gashawa Co. Developed by
      &nbsp;<a
        class="blackAce"
        href="https://www.blackace.tech/"
        target="_blank"
      >
        Black Ace</a
      >
    </footer></teleport
  >
</template>

<script>
export default {
  props: ["withContact"],
  inject: ["content"],
};
</script>

<style scoped>
</style>