<template>
  <div class="home-slide">
    <div class="background" :style="`background: url(${img})`">
      <div class="background-overlay layout-padding">
        <div class="slide-mt">
          <div class="title">
            {{ title }}
          </div>
          <div class="subtitle mt-6">
            {{ subtitle }}
          </div>
          <button
            class="learn-more-btn mt-10 text-primary"
            @click="$router.push('/about')"
          >
            <div class="flex items-center">
              {{ content.learnMore }}
              <img src="/static/images/arrow.svg" class="btn-arrow" />
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "subtitle", "img"],
  inject: ["content"],
};
</script>