import http from "/src/httpRequest.js";
export default {
  actions: {
    async getHomeSlider(context) {
      const response = await http.get("homeSliders");
      context.commit("setHomeSlider", response.data);
      context.commit("setHome", response);
    },
  },
};
