<template>
  <div
    class="service-card-img relative"
    :style="`background: url(${service.image})`"
  >
    <div class="service-card-rectangles">
      <div class="rectangle-shape"></div>
      <div class="rectangle-shape mx-4"></div>
      <div class="rectangle-shape"></div>
    </div>
    <div class="img-overlay-two"></div>
  </div>
  <div class="service-section mt-6">
    <div class="flex flex-col justify-center">
      <div class="title my-4">{{ service.title }}</div>
      <div class="content mt-2">
        {{ service.description }}
      </div>
    </div>
  </div>
  <button
    class="btn-outline mt-12"
    @click="$router.push(`/services/${service.id}`)"
  >
    <div class="flex items-center">
      {{ content.readMore }}
      <img src="/static/images/arrow.svg" class="btn-arrow" />
    </div>
  </button>
</template>
<script>
export default {
  props: ["service"],
  inject: ["content"],
};
</script>
<style scoped>
.btn-outline {
  width: max-content;
}
</style>