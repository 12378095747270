<template>
  <div class="loading">
    <div class="loading-logo">
      <img src="/static/images/logo/1.png" class="loading-img" />
      <img src="/static/images/logo/2.png" class="loading-img" />
      <img src="/static/images/logo/3.png" class="loading-img" />
      <img src="/static/images/logo/4.png" class="loading-img" />
      <!--       <div class="loading-overlay"></div>
 -->
    </div>
  </div>
</template>

<style scoped>
.loading-overlay {
  background: rgb(14, 39, 61);
  background: linear-gradient(
    0deg,
    rgba(14, 39, 61, 1) 0%,
    rgba(0, 212, 255, 0) 100%
  );

  height: 50px;
  position: absolute;
  bottom: 0;
  width: 200px;
  left: 0;
}
.loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #0e273d;
  z-index: 999;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-logo {
  position: absolute;
  display: flex;
  padding: 0px;
  bottom: calc(100% - 550px);
  width: 120px;
  overflow-y: hidden;
  height: 100%;
  overflow-x: hidden;
}

.loading-img:nth-child(1) {
  object-fit: contain;
  position: absolute;
  bottom: 50px;
  left: 0px;
  animation-duration: 4s;
  animation-name: fireanimate1;
  animation-iteration-count: infinite;
}
.loading-img:nth-child(2) {
  object-fit: contain;
  position: absolute;
  bottom: 50px;
  left: 10px;
  animation-duration: 4s;
  animation-name: fireanimate2;
  animation-iteration-count: infinite;
}
.loading-img:nth-child(3) {
  object-fit: contain;
  position: absolute;
  bottom: 85px;
  left: 30px;
  animation-duration: 4s;
  animation-name: fireanimate3;
  animation-iteration-count: infinite;
}
.loading-img:nth-child(4) {
  object-fit: contain;
  position: absolute;
  bottom: 50px;
  left: 50px;
  animation-duration: 4s;
  animation-name: fireanimate4;
  animation-iteration-count: infinite;
}
@keyframes fireanimate1 {
  0% {
    bottom: -200px;
    opacity: 0;
  }
  20% {
    opacity: 0;
  }

  60% {
    bottom: 50px;
    opacity: 1;
  }
  100% {
    bottom: 200px;
    opacity: 0;
  }
}
@keyframes fireanimate2 {
  0% {
    bottom: -200px;
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    bottom: 50px;
    opacity: 1;
  }
  95% {
    bottom: 200px;
    opacity: 0;
  }
  100% {
    bottom: 200px;
    opacity: 0;
  }
}
@keyframes fireanimate3 {
  0% {
    bottom: -200px;
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  40% {
    bottom: 75px;
    opacity: 1;
  }
  80% {
    bottom: 200px;
    opacity: 0;
  }
  100% {
    bottom: 200px;
    opacity: 0;
  }
}
@keyframes fireanimate4 {
  0% {
    bottom: -200px;
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  55% {
    bottom: 50px;
    opacity: 1;
  }

  90% {
    bottom: 200px;
    opacity: 0;
  }
  100% {
    bottom: 200px;
    opacity: 0;
  }
}
</style>