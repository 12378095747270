<template>
  <Loading v-if="!services" />
  <div class="services-container layout-padding my-12 lg:my-24 relative" v-else>
    <div class="page-title">{{ content.whatWeDo }}</div>
    <div class="services-page-underlay uppercase">{{ content.services }}</div>
    <div class="grid grid-cols-2 gap-24 mt-20">
      <div
        class="col-span-2 lg:col-span-1"
        v-for="service in services"
        :key="service.id"
      >
        <ServiceCard :service="service" />
      </div>
    </div>
  </div>
  <TheFooter />
</template>
<script>
import ServiceCard from "/src/components/ui/ServiceCard.vue";
export default {
  components: {
    ServiceCard,
  },
  inject: ["content"],
  created() {
    document.getElementById("main-header").classList.add("bg-primary");
  },
  beforeUnmount() {
    document.getElementById("main-header").classList.remove("bg-primary");
  },
  methods: {
    async loadData() {
      await this.$store.dispatch("services/getServices");
    },
  },
  computed: {
    services() {
      return this.$store.getters["services/services"];
    },
  },
  mounted() {
    if (!this.services) this.loadData();
  },
};
</script>
