export default {
  mutations: {
    setServices(state, payload) {
      state.services = payload;
    },
    setServicesDetail(state, payload) {
      state.servicesDetail = payload;
    },
    clearServiceDetail(state, payload) {
      state.servicesDetail = payload;
    },
  },
};
