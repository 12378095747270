import { createStore } from "vuex";
import Home from "/src/store/Modules/Home/index.js";
import Contact from "/src/store/Modules/Contact/index.js";
import Services from "/src/store/Modules/Services/index.js";
import Operations from "/src/store/Modules/Operations/index.js";
import WhoWeAre from "/src/store/Modules/WhoWeAre//index.js";
import Products from "./Modules/Products";
import ProductType from "./Modules/ProductType";

export default createStore({
  modules: {
    home: Home,
    contact: Contact,
    services: Services,
    products: Products,
    productType: ProductType,
    operations: Operations,
    whoWeAre: WhoWeAre,
  },
});
