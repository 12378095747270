<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.941"
    height="22.945"
    viewBox="0 0 22.941 22.945"
  >
    <path
      id="Icon_awesome-search"
      data-name="Icon awesome-search"
      d="M22.629,19.838,18.162,15.37a1.075,1.075,0,0,0-.762-.314h-.73a9.316,9.316,0,1,0-1.613,1.613v.73a1.075,1.075,0,0,0,.314.762l4.468,4.468a1.071,1.071,0,0,0,1.519,0l1.268-1.268a1.081,1.081,0,0,0,0-1.524ZM9.321,15.056a5.736,5.736,0,1,1,5.736-5.736A5.732,5.732,0,0,1,9.321,15.056Z"
      fill="#fff"
    />
  </svg>
</template>