export default {
  getters: {
    products(state) {
      return state.products;
    },
    productsDetail(state) {
      return state.productsDetail;
    },
  },
};
