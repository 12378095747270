<template>
  <div class="operate-container flex flex-col justify-center items-center">
    <div class="layout-padding w-full flex flex-col items-center">
      <div class="home-operate-title">{{ content.whereWeOperate }}</div>
      <div class="home-operate-subtitle w-full mt-6">
        {{ operate[0].subtitle }}
      </div>
      <router-link to="/operate">
        <div class="font-bold">{{ content.readMore }}</div></router-link
      >
    </div>
    <Map />
  </div>
</template>

<script>
import Map from "/src/components/ui/Map.vue";
export default {
  props: ["operate"],
  inject: ["content"],
  components: {
    Map,
  },
};
</script>