<template>
  <SkeletonLoading v-if="!servicesDetail" />

  <div class="my-12 lg:my-24 grid grid-cols-2 layout-padding order-last" v-else>
    <div class="col-span-2 lg:col-span-1 lg:hidden flex">
      <div
        class="service-detail-image"
        :style="`background: url(${servicesDetail?.image})`"
      >
        <div class="img-overlay-two p-8">
          <div class="home-values-border"></div>
        </div>
      </div>
    </div>
    <div
      class="
        col-span-2
        lg:col-span-1
        flex flex-col
        justify-center
        lg:mb-0
        my-12
      "
    >
      <div class="relative">
        <div class="page-title mb-6 lg:mb-16">{{ servicesDetail?.title }}</div>
        <div class="services-page-underlay-two uppercase">
          {{ content.services }}
        </div>
      </div>
      <div class="service-detail-text">
        {{ servicesDetail?.description }}
      </div>
      <button
        class="btn-back mt-12 text-primary"
        @click="$router.back()"
        v-if="servicesDetail?.title.toLowerCase() != 'steel'"
      >
        <div class="flex items-center">
          <img src="/static/images/arrow.svg" class="back-arrow" />

          {{ content.backToServices }}
        </div>
      </button>
    </div>
    <div class="col-span-2 lg:col-span-1 lg:flex hidden">
      <div
        class="service-detail-image"
        :style="`background: url(${servicesDetail?.image})`"
      >
        <div class="img-overlay-two p-8">
          <div class="home-values-border"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="border-t border-tableBg -mt-10"></div>
  <div
    class="my-12 lg:my-12 grid grid-cols-12 gap-x-0 lg:gap-x-20 layout-padding"
    v-if="servicesDetail?.id == '1'"
  >
    <div class="page-title col-span-12 mb-6 lg:mb-16">
      {{ servicesDetail?.title }} {{ content.products }}
    </div>
    <!-- <div class="col-span-12">
      <select
        v-model="selectedProduct"
        class="w-full py-6 px-4 rounded-xl mt-4 mb-12 flex lg:hidden"
      >
        <option value="finished">finished</option>
        <option value="Billets">Semi Finished Product</option> -->
    <!-- <option value="Slab">Slab</option> -->
    <!-- <option value="MainRawMaterials">Basic Raw Materials</option>
        <option value="FerroAlloys">Ferro Alloys</option>
        <option value="Slab">Slab</option>
        <option value="Coal">Carbon</option>
        <option value="Refractories">Refractories</option>
        <option value="Calcined">Calcined</option>
        <option value="fluorspar">fluorspar</option>
        <option value="graphite">Graphite Electrodes</option>
      </select>
    </div> -->
    <div class="w-full col-span-12">
      <ul
        class="  grid lg:grid-cols-5 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-y-5 gap-x-3 lg:gap-x-5  "
      >
        <li
          @click="selectProduct(product.title)"
          v-for="product in products"
          :key="product"
        >
          <div
            class="ProductItem relative rounded-md border border-tableBg shadow-sm flex flex-col gap-5 items-center"
            style="width: 100%; height: 300px; overflow: hidden;"
          >
            <img
              class="ProductImage rounded-md w-full h-full object-cover"
              :src="product?.image"
              alt=""
            />
            <span
              class="bg-black bg-opacity-50 text-lg text-white font-semibold absolute bottom-0 left-0 text-start py-2 px-2 w-1/2 xl:w-1/4"
            >
              {{ product?.title }}
            </span>
          </div>
        </li>

        <!-- <li
          :class="{ 'active-product': selectedProduct == 'Slab' }"
          @click="selectedProduct = 'Slab'"
        >
          Slab
        </li> -->
        <!-- <li
          :class="{ 'active-product': selectedProduct == 'MainRawMaterials' }"
          @click="selectProduct('MainRawMaterials')"
        >
          Basic Raw Materials
        </li>
        <li
          :class="{ 'active-product': selectedProduct == 'FerroAlloys' }"
          @click="selectProduct('FerroAlloys')"
        >
          Ferro Alloys
        </li>
        <li
          :class="{ 'active-product': selectedProduct == 'graphite' }"
          @click="selectProduct('graphite')"
        >
          Graphite Electrodes
        </li>
        <li
          :class="{ 'active-product': selectedProduct == 'Refractories' }"
          @click="selectProduct('Refractories')"
        >
          Refractories
        </li>

        <li
          :class="{ 'active-product': selectedProduct == 'Coal' }"
          @click="selectProduct('Coal')"
        >
          Carbon
        </li>
        <li
          :class="{ 'active-product': selectedProduct == 'fluorspar' }"
          @click="selectProduct('fluorspar')"
        >
          Fluorspar
        </li>
        <li
          :class="{ 'active-product': selectedProduct == 'Calcined' }"
          @click="selectProduct('Calcined')"
        >
          Calcined (Lime , Dolomite)
        </li> -->
      </ul>
    </div>
    <div class="col-span-12 lg:col-span-9">
      <!-- <swiper
        class="swiper-container"
        :slides-per-view="1"
        :loop="true"
        :space-between="0"
        @swiper="setThumbsSwiper"
        :effect="'fade'"
      >
        <swiper-slide v-for="img in slideImgs[selectedProduct]" :key="img"
          ><img :src="img.image" class="products-img"
        /></swiper-slide>
      </swiper>
      <swiper class="swiper-container" :breakpoints="breakpoints">
        <swiper-slide
          v-for="(img, index) in slideImgs[selectedProduct]"
          :key="img"
        >
          <div class="flex flex-col items-center">
            <div
              :style="`background:url(${img.image})`"
              class="thumb-img"
              @click="slideTo(index)"
            />
            <div class="mt-2 text-lg font-bold">{{ img.name }}</div>
          </div>
        </swiper-slide>
      </swiper> -->
      <!--  <div v-if="selectedProduct == 'Billets'">
        <div class="title font-bold text-3xl mb-5">Steel billet</div>
        <div class="title font-bold text-3xl mb-5">Steel Slab</div>
         Billets are the feedstock for long products of small cross section. In
        cases when they are not directly cast by a continuous caster, they are
        rolled from blooms by billet mills. One method of rolling billets, which
        are usually 75 to 125 millimetres square, is to use a three-high mill
        with box passes, as shown in A in the figure. After a rectangular bloom
        is rolled into a square cross section at the lower rolls, it is lifted
        to the next pass on the upper rolls and rolled back into a rectangular
        one; this is turned 90° while being lowered on a roller table for
        another square rolling in the lower pass, and so on. In another method,
        alternating horizontal and vertical stands are arranged in tandem, using
        diamond and square passes without turning or twisting the billet.
        <br />
        Our ordinery grades are:
        <br />
        ASTM A615 Gr60
        <br />
        ASTM A572GR50
        <br />
        BS 4449 B500B CK 45
        <br />
        RSt 37-2
        <br />
        St 3sp St 3sp St 44-2
        <br />
        St 5sp 2
        <br />
        ISIRI 3132 Aj 400
        <br />

        Analysis of grades are according to customer needs and negotiable. Other
        Grades are negotiable. <br /> -->
      <!-- <div
          class="overflow-x-auto my-12 bg-tableBg text-primary p-6 rounded-xl"
        >
          <table width="992">
            <tbody>
              <tr>
                <td colspan="2"><strong> Dimention & Tolerances</strong></td>
              </tr>
              <tr>
                <td><strong>Cross section (mm)</strong></td>
                <td><strong>Length (mm)</strong></td>
              </tr>
              <tr>
                <td>130×130 (+/-4mm)</td>
                <td>8000-12000 (+/- 100mm)</td>
              </tr>
              <tr>
                <td>150×150 (+/- 5mm)</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div> 
      </div>-->
      <!-- <div v-if="selectedProduct == 'Slab'">
        <div class="title font-bold text-3xl mb-5">Slab</div>
        This is described as semi-finished steel and is the end result of
        rolling ingots on a rolling mill or by processing them through a
        continuous casting process. Slabs will have rectangular cross sections
        and are used as the “starter” metals in the making of flat products such
        as steel plates or hot rolled coils. Slabs are always flat and
        rectangular and will typically range from 160mm thicknesses and more.
        They are used in an astonishing number of industries, though not always
        the same as billets or blooms. The most common industries purchasing
        steel slab relate to heavy machinery and the production of plates or
        coils. Not unlike steel billets, slabs are formed for further
        processing, but are always in that distinctive rectangular shape. They
        are then typically processed using flat rolling and will be most often
        converted into sheet metal, chequered plates, pipes and tubes. While
        available in stainless, the most common is carbon steel. The chemical
        composition will vary, but most feature iron, chromium, copper,
        manganese, molybdenum, nickel and silicon. They too must be identified
        using standard coding, and this is typically attached or imprinted into
        the end of the slab.
        <br />
        Our ordinery grades are: <br />G3101 SS 400

        <br />G3106 SM 400A SAE 1008

        <br />St 37-2

        <br />St 44-3

        <br />St 52-3
        <br />
        Gl Structural Steel For Hull Construction
        <br />
        Analysis of grades are according to customer needs and negotiable. Other
        Grades are negotiable.
        <div
          class="overflow-x-auto my-12 bg-tableBg text-primary p-6 rounded-xl"
        >
          <table width="992">
            <tbody>
              <tr>
                <td colspan="3"><strong> Dimention & Tolerances</strong></td>
              </tr>
              <tr>
                <td><strong>Thicness (mm)</strong></td>
                <td><strong>Width (mm)</strong></td>
                <td><strong>Length (mm)</strong></td>
              </tr>
              <tr>
                <td>195 ± 5</td>
                <td>1000-1250 ± 10</td>
                <td>3800-10500 ± 100</td>
              </tr>
              <tr>
                <td>245 ± 5</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> -->
      <!-- <div v-if="selectedProduct == 'finished'">
        <div class="title font-bold text-3xl mb-5">Wire Rods</div>
        Used for an array of products based on how it is formed and treated,
        steel wire rods can be used for wire mesh manufacturing, the production
        of nails and springs, as reinforcement wire, barbed wire and even formed
        into wire ropes or welded wire mesh. Any steel wire rod is semi-finished
        and is cold drawn for further processing. Available in a variety of
        shapes from rounded, squared, hexagonal or rectangular, it is typically
        rolled round. Diameters are going to range from 5mm to 15mm, with most
        adhering to the international ISO standards, allowing sizes to range
        from those indicated above and upon reaching 15mm (in increments of .5mm
        until that point) advancing in increments of 1mm until a size of no
        greater than 20mm. All steel wire rods is formed into coils and without
        break. This rolled condition is to ensure steel wire rods are properly
        prepared for further processing (typically by cleaning, coating and heat
        treating). The internal diameter of coils typically ranges from 810mm to
        910mm, and the external from 1100mm to 1300mm (based on the mill’s
        equipment capability). Weights on coils range accordingly with lower
        weights at roughly 600kg and larger at more than two tons. All steel
        wire rods are produced in varying grades and based on the type of
        material. Carbon steel wire rod is the most common and for which there
        are no minimum content requirements of aluminum (Al), chromium (Cr),
        cobalt (Co), nickel (Ni), niobium (Nb), molybdenum (Mo), titanium (Ti),
        tungsten (W), vanadium (V), zirconium (Zr), or any other element needed
        for a specific alloying effect. Carbon steel wire rods will have copper
        content guidelines, typically not to exceed .40%. Additionally, the
        manganese content of carbon steel wire rods is not to breach the 1.65%
        level and silicon may not exceed the .60% mark. Carbon steel wire rods
        are available in the following grades (Note, the “C” designates carbon):
        Low C grades will have maximum C content of less than or equal to 0.15
        %. This is also called mild steel or electrode steel. Medium C grades
        are high or low. The low will have maximum C content greater than 0.15 %
        but less or equal to 0.23%, while the high grades will have maximum C
        content greater than 0.23 % but less or equal to 0.44 %. High Carbon
        grades will have maximum C content greater than 0.44 %. In addition to
        the grades of steel wire rods, there are also qualities that are
        guaranteed through various procedures and intended to guarantee freedom
        from defects as well as the right performance based on the intended
        application. The most common are:
        <li class="product-items-list my-4">
          <strong> Drawing quality</strong> – Also known as industrial quality
          is typically low to medium grade and meant for drawing into industrial
          strength wire. Fine wire quality can be drawn, too.
        </li>

        <li class="product-items-list my-4">
          <strong> Cold heading quality </strong> is known as cold extrusion or
          cold rolling quality and made under the closest regulations. They can
          be made of higher C grades.
        </li>
        <li class="product-items-list my-4">
          <strong> Electrode quality </strong> – These are produced to have
          specific chemical compositions meant to work best as wires with
          mechanical and/or electrical properties.
        </li>
        <li class="product-items-list my-4">
          <strong> Mesh quality </strong>– Made from low to medium steel, these
          wire rods are meant for drawing and cold rolling into wire that is
          then welded into wire mesh, typically for the reinforcement of
          concrete.
        </li>
      </div> -->
      <!-- <div v-if="selectedProduct == 'Refractories'">
        <div class="title font-bold text-3xl mb-5">Refractories</div>
        <div class="grid grid-cols-2">
          <div class="col-span-2 md:col-span-1">
            <strong>Basic</strong>

            <li class="product-items-list my-4">Magnesia Bricks</li>
            <li class="product-items-list my-4">Magnesia Chrome Bricks</li>
            <li class="product-items-list my-4">
              Direct Bonded Magnesia Chrome Bricks
            </li>
            <li class="product-items-list my-4">Magnesia Carbon Bricks</li>
            <li class="product-items-list my-4">Basic Mortars</li>
            <li class="product-items-list my-4">Basic Ramming Masses</li>
            <li class="product-items-list my-4">Basic Gunning Masses</li>
            <li class="product-items-list my-4">Basic Spraying Masses</li>
            <li class="product-items-list my-4">Basic Fetling Masses</li>

            <strong>DOLOMITE</strong>
            <li class="product-items-list my-4">Dolomite Bricks</li>
            <li class="product-items-list my-4">Dolomite Monolithics</li>
            <strong> FIRECLAY & HIGH ALUMINA</strong>

            <li class="product-items-list my-4">
              Fireclay & High Alumina Bricks
            </li>
            <li class="product-items-list my-4">
              Fireclay & High Alumina Ceramic Setting Mortars
            </li>
            <li class="product-items-list my-4">
              Fireclay & High Alumina Plastic Masses
            </li>
            <li class="product-items-list my-4">
              Fireclay & High Alumina Castables
            </li>
            <li class="product-items-list my-4">Insulating Castables</li>
            <li class="product-items-list my-4">
              Low Cement High Alumina Castables
            </li>
            <li class="product-items-list my-4">Coke Oven Gunning Mixes</li>
            <li class="product-items-list my-4">Coke Oven Spraying Mixes</li>
            <li class="product-items-list my-4">Ultra Low Cement Castables</li>
            <li class="product-items-list my-4">
              Conventional Dense Castables
            </li>
            <li class="product-items-list my-4">Insulating Castables</li>
            <strong>SILICA</strong>
            <li class="product-items-list my-4">Silica Bricks</li>
            <li class="product-items-list my-4">Silica Mortars</li>
            <li class="product-items-list my-4">Silica Ramming Masses</li>
          </div>
          <div class="col-span-2 md:col-span-1">
            <strong>FLOW CONTROL</strong>
            <li class="product-items-list my-4">Slide Gate Refractories</li>
            <li class="product-items-list my-4">Gus Purging Refractories</li>
            <li class="product-items-list my-4">Tundish Refractories</li>
            <strong> SPECIAL REFRACTORIES</strong>
            <li class="product-items-list my-4">Silicon Carbide Bricks</li>
            <li class="product-items-list my-4">Zircon Bricks</li>

            <strong>INDUCTION FURNACE MAIN LININGS</strong>

            <li class="product-items-list my-4">Neutral Linings</li>
            <li class="product-items-list my-4">Basic Linings</li>
            <li class="product-items-list my-4">Silica Linings</li>

            <strong> REFRACTORY RAW MATERIALS MAGNESITE</strong>
            <li class="product-items-list my-4">Dead Burned Magnesite</li>
            <li class="product-items-list my-4">Fused Magnesite</li>
            <strong>Graphite Electrodes</strong>
            <li class="product-items-list my-4">Natural Flake Graphite</li>
            <li class="product-items-list my-4">Amorphous Graphite</li>

            <strong>SPINELS</strong>
            <li class="product-items-list my-4">
              Sintered Alumina-Magnesia Spinel
            </li>
            <li class="product-items-list my-4">
              Fused Alumina- Magnesia Spinel
            </li>

            <strong>ALUMINA</strong>
            <li class="product-items-list my-4">Rotary Kiln Bauxite</li>
            <li class="product-items-list my-4">Shaft Kiln Bauxite</li>
            <li class="product-items-list my-4">White Fused Alumina</li>
            <li class="product-items-list my-4">Brown Fused Alumina</li>
            <li class="product-items-list my-4">Sintered Mullite</li>

            <strong>ALUMINA-SILICATE</strong>
            <li class="product-items-list my-4">Flint Clay</li>
            <li class="product-items-list my-4">KAOLIN</li>
            <li class="product-items-list my-4">
              SILICON CARBIDE (Refractory Grade)
            </li>
            <li class="product-items-list my-4">EAF Casting Hole Sand</li>
          </div>
        </div>
      </div> -->
      <!--  <div v-if="selectedProduct == 'MainRawMaterials'">
       <div class="title font-bold text-3xl mb-5">Scrap Steel</div>
        Scrap Steel Scrap steel comes from many different sources because it is
        very easily recycled. Some scrap comes from within the steelworks, where
        it might have been damaged or is at the end of a batch of one type of
        steel. It also comes from old car bodies, old ship containers, and
        buildings that have been demolished. Another source of scrap can be
        found in our homes. Steel cans (food cans, pet food cans, aerosols,
        paint cans, etc.) are collected as part of council curbside collections
        and can be recycled an infinite number of times.
        <div class="title font-bold text-3xl mb-5 mt-12">Pig Iron</div>
        Pig iron is the product of smelting iron ore (also ilmenite) with a
        high-carbon fuel and reductant such as coke, usually with limestone as a
        flux. Charcoal and anthracite are also used as fuel and reductant.
        <div
          class="overflow-x-auto my-12 bg-tableBg text-primary p-6 rounded-xl"
        >
          <table width="992">
            <tbody>
              <tr>
                <td width="168"></td>
                <td width="110">C</td>
                <td width="117">Si</td>
                <td width="111">Mn</td>
                <td width="82">P</td>
                <td width="107">S</td>
              </tr>
              <tr>
                <td>L1</td>
                <td>3.50-4.50 %</td>
                <td>3.20-3.60 %</td>
                <td>0.40-0.80 %</td>
                <td>0.08 % max</td>
                <td>0.04 % max</td>
              </tr>
              <tr>
                <td>L2</td>
                <td>3.50-4.50 %</td>
                <td>2.80-3.20 %</td>
                <td>0.40-0.80 %</td>
                <td>0.08 % max</td>
                <td>0.04 % max</td>
              </tr>
              <tr>
                <td>L3</td>
                <td>3.50-4.50 %</td>
                <td>2.40-2.80 %</td>
                <td>0.40-0.80 %</td>
                <td>0.08 % max</td>
                <td>0.04 % max</td>
              </tr>
              <tr>
                <td>L4</td>
                <td>3.50-4.50 %</td>
                <td>2.00-2.40 %</td>
                <td>0.40-0.80 %</td>
                <td>0.08 % max</td>
                <td>0.04 % max</td>
              </tr>
              <tr>
                <td>L5</td>
                <td>3.50-4.50 %</td>
                <td>1.60-2.00 %</td>
                <td>0.40-0.80 %</td>
                <td>0.08 % max</td>
                <td>0.04 % max</td>
              </tr>
              <tr>
                <td>L6</td>
                <td>3.50-4.50 %</td>
                <td>1.20-1.60 %</td>
                <td>0.40-0.80 %</td>
                <td>0.08 % max</td>
                <td>0.04 % max</td>
              </tr>
              <tr>
                <td>L5 – L6 Low Mn</td>
                <td>3.50-4.50 %</td>
                <td>1.00-2.00 %</td>
                <td>0.099 % max</td>
                <td>0.08 % max</td>
                <td>0.04 % max</td>
              </tr>
              <tr>
                <td>SIZE</td>
                <td colspan="5">10-12 kg ingots without notches</td>
              </tr>
              <tr>
                <td>PACKING</td>
                <td colspan="5">Bulk</td>
              </tr>
            </tbody>
          </table>
        </div> 
        <div class="title font-bold text-3xl mb-5 mt-12">
          Direct Reduced Iron (DRI)
        </div>-->
      <!-- Direct Reduced Iron (DRI) is the product of the direct reduction of iron
        ore in the solid state by carbon monoxide and hydrogen derived from
        natural gas or coal. See more information about the production of DRI.
        Most gas-based direct reduction plants are part of integrated steel
        mini-mills, located adjacent to the electric arc furnace (EAF) steel
        plant. DRI can be either hot or cold charged to the EAF. Some steel
        companies ship DRI from their captive direct reduction plants to their
        remote steel mills and a small volume of DRI is sold to third parties.
        In India there are many small rotary kiln furnaces producing DRI, known
        locally as sponge iron, using coal as energy and reductant source. Some
        of the sponge iron plants are captive to steel mills, but there is a
        significant domestic merchant market, India producing 57% of its crude
        steel in electric arc furnaces (2016). 
        <div class="title font-bold text-3xl mb-5 mt-12">
          Hot Briquetted Iron (HBI)
        </div>-->
      <!-- Hot Briquetted Iron (HBI) is a premium form of DRI that has been
        compacted at a temperature greater than 650° C at time of compaction and
        has a density greater than 5,000 kilograms per cubic metre (5,000
        kg/m3). HBI was developed as a product in order to overcome the problems
        associated with shipping and handling of DRI - due to the process of
        compaction it is very much less porous and therefore very much less
        reactive than DRI and does not suffer from the risk of self-heating
        associated with DRI. The principle market for HBI is electric arc
        furnace (EAF) steelmaking, but HBI also finds application as a trim
        coolant in basic oxygen furnace (BOF) steelmaking and as blast furnace
        feedstock.
      </div> -->
      <!-- <div v-if="selectedProduct == 'FerroAlloys'">
        <div class="title font-bold text-3xl mb-5">Ferro Silicon</div>
        Ferro Silicon is a universal “heat-blocker” used in the production of
        carbon and stainless steels. This additive is used with other ferro
        alloys in the deoxidising process of steel, as well as in the production
        of silicon itself. It is also used in the production of cast iron, as it
        can accelerate graphitisation. Ferro Silicon replaces the need for ferro
        manganese, spiegeleisen and calcium silicides in the manufacturing
        process.
        <div
          class="overflow-x-auto my-12 bg-tableBg text-primary p-6 rounded-xl"
        >
          <table width="992">
            <tbody>
              <tr>
                <td width="168"></td>
                <td width="110">Si</td>
                <td width="117">Al</td>
                <td width="111">C</td>
                <td width="82">P</td>
                <td width="107">S</td>
              </tr>
              <tr>
                <td>FeSi 45 %</td>
                <td>45 % min</td>
                <td>2.00 % max</td>
                <td>0.20 % max</td>
                <td>0.03% max</td>
                <td>0.02 % max</td>
              </tr>
              <tr>
                <td>FeSi 65 %</td>
                <td>65 % min</td>
                <td>1.50 % max</td>
                <td>0.15 % max</td>
                <td>0.03% max</td>
                <td>0.02 % max</td>
              </tr>
              <tr>
                <td>FeSi 75 %</td>
                <td>75 % min</td>
                <td>1.50 % max</td>
                <td>0.15 % max</td>
                <td>0.03% max</td>
                <td>0.02 % max</td>
              </tr>
              <tr>
                <td width="168">
                  FeSi 75 %<br />
                  Low Al
                </td>
                <td>75 % min</td>
                <td>1.00 % max</td>
                <td>0.05 % max</td>
                <td>0.03% max</td>
                <td>0.02 % max</td>
              </tr>
              <tr>
                <td width="168">
                  FeSi 75 %<br />
                  High Purity
                </td>
                <td>75 % min</td>
                <td>0.10 % max</td>
                <td>0.03 % max</td>
                <td>0.03% max</td>
                <td>0.02 % max</td>
              </tr>
              <tr>
                <td>SIZE</td>
                <td colspan="5">1-3 mm / 3-10 mm / 10-50 mm / 10-100 mm</td>
              </tr>
              <tr>
                <td>PACKING</td>
                <td colspan="5">Bulk or 1 mt big-bag</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="title font-bold text-3xl mb-5">Ferro Silicon Manganese</div>
        Ferro Silico Manganese is used as a deoxidizer and an alloying element
        in steel. It can be used as a substitute for Ferro Silicon and Ferro
        Manganese when added to make different types of steel.
        <div
          class="overflow-x-auto my-12 bg-tableBg text-primary p-6 rounded-xl"
        >
          <table width="992">
            <tbody>
              <tr>
                <td width="168"></td>
                <td width="110">Mn</td>
                <td width="117">Si</td>
                <td width="111">C</td>
                <td width="82">P</td>
                <td width="107">S</td>
              </tr>
              <tr>
                <td>FeSiMn 6014</td>
                <td>60 % min</td>
                <td>14 % min</td>
                <td>2.0 % max</td>
                <td>0.30 % max</td>
                <td>0.05 % max</td>
              </tr>
              <tr>
                <td width="168">FeSiMn 6517</td>
                <td>65 % min</td>
                <td>17 % min</td>
                <td>2.0 % max</td>
                <td>0.30 % max</td>
                <td>0.03 % max</td>
              </tr>
              <tr>
                <td>FeSiMn 7018</td>
                <td>70 % min</td>
                <td>17 % min</td>
                <td>2.0 % max</td>
                <td>0.30/0.50 % max</td>
                <td>0.03 % max</td>
              </tr>
              <tr>
                <td>SIZE</td>
                <td colspan="5">10-50 mm / 10-80 mm</td>
              </tr>
              <tr>
                <td>PACKING</td>
                <td colspan="5">Bulk or 1 mt big-bag</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="title font-bold text-3xl mb-5">Ferro Manganese</div>
        Ferro-manganese is used mainly in the steel industry for hardening and
        desulphurisation of steel and as a deoxidizer, making the slag more
        fluid..
        <li class="product-items-list">Low Carbon</li>
        <li class="product-items-list">Medium Carbon</li>
        <li class="product-items-list">High Carbon</li>

        <div
          class="overflow-x-auto my-12 bg-tableBg text-primary p-6 rounded-xl"
        >
          <table width="992">
            <tbody>
              <tr>
                <td width="168"></td>
                <td width="110">Mn</td>
                <td width="117">C</td>
                <td width="111">Si</td>
                <td width="82">P</td>
                <td width="107">S</td>
              </tr>
              <tr>
                <td>FeMn HC</td>
                <td>75 % min</td>
                <td>6-8 %</td>
                <td>1.50 % max</td>
                <td>0.25 % max</td>
                <td>0.03 % max</td>
              </tr>
              <tr>
                <td width="168">
                  FeMn HC<br />
                  Low P
                </td>
                <td>76/78 % min</td>
                <td>6-8 %</td>
                <td>1.50 % max</td>
                <td>0.10 % max</td>
                <td>0.03 % max</td>
              </tr>
              <tr>
                <td>FeMn MC</td>
                <td>80 % min</td>
                <td>1.50 % max</td>
                <td>1.50 % max</td>
                <td>0.20 % max</td>
                <td>0.03 % max</td>
              </tr>
              <tr>
                <td width="168">FeMn LC</td>
                <td>80 % min</td>
                <td>0.50 % max</td>
                <td>0.50 % max</td>
                <td>0.025 % max</td>
                <td>0.03 % max</td>
              </tr>
              <tr>
                <td>SIZE</td>
                <td colspan="5">1-3 mm / 3-10 mm / 10-50 mm / 10-100 mm</td>
              </tr>
              <tr>
                <td>PACKING</td>
                <td colspan="5">Bulk or 1 mt big-bag</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="selectedProduct == 'Coal'">
        <div class="title font-bold text-3xl mb-5">Powder</div>
        Coal dust is a fine powdered form of which is created by the crushing,
        grinding, or pulverizing of coal. Because of the brittle nature of coal,
        coal dust can be created during mining, transportation, or by
        mechanically handling coal. It is a form of fugitive dust. Grinding coal
        to dust before combusting it improves the speed and efficiency of
        burning and makes the coal easier to handle. However, coal dust is
        hazardous to workers if it is suspended in air outside the controlled
        environment of grinding and combustion equipment. It poses the acute
        hazard of forming an explosive mixture in air and the chronic hazard of
        causing pulmonary illness in people who inhale excessive quantities of
        it. The distribution of the particle-size of coal dust is frequently
        measured in mesh. The British slang term for cheap fuel consisting of
        coal dust (slack) containing small lumps of coal (nuts) is nutty slack.
        <div class="title font-bold text-3xl mb-5 mt-12">Charge</div>
        It is used in electric arc furnaces. Such furnaces are widely used to
        produce steel from all-metal raw material (scrap, DRI, HBI, etc.). The
        charge materials added to the furnace include metal, a source of calcium
        oxide, a source of magnesium oxide, and a source of carbon. The carbon
        introduced together with the charge is commonly anthracite or a mixture
        that ranges from 3″ to 1/4″ and has a percentage of fixed carbon greater
        than 75%.<br />
        Charge coal is important to electric arc furnace operation:
        <li class="product-items-list my-4">
          It prevents over-oxidation of metal filler and flux material prior to
          melting.
        </li>
        <li class="product-items-list my-4">
          It accelerates the formation of slag.
        </li>
        <li class="product-items-list my-4">
          It provides a considerable amount of energy required for steelmaking
          operations from its reaction with oxygen to produce carbon monoxide.
          This has led to substantial reductions in energy consumption in the
          electric arc furnace.
        </li>
        For its part, carbon monoxide is key to achieving low concentrations of
        dissolved gases in steel, which are evacuated along with carbon
        monoxide. Furthermore, the oxide inclusions present in the steel flow
        into the slag. Carbon recovery from charge coal in electric arc furnaces
        is not always constant. It has been shown that the size and quality of
        the charge coal is very important to achieve good and constant
        recoveries of carbon in steel.
        <div class="title font-bold text-3xl mb-5 mt-12">Injection</div>
        It is used in electric arc furnaces for the production of steel
        transforming solid metallic raw material into a liquid state (DRI, HBI
        and scrap), through the electric arcs generated by graphite electrodes
        while making contact with the metallic charge. To increase the thermal
        efficiency of the furnace during the process, the foam slag technique is
        used. This is formed on the molten metal and rises covering the arc of
        the electrodes ensuring that the electrical energy is transferred
        directly to the molten bath to take advantage of the heat generated,
        allowing the arc to operate at high voltages without increasing the
        thermal load by radiation on the refractory of the furnace. This
        achieves increases in thermal efficiency of up to 90%. Submerging the
        arc also helps prevent nitrogen from being exposed to the arc where it
        can dissociate and enter the steel.
        <br />
        The formation of the foamy slag can be divided into two stages:
        <li class="product-items-list my-4">
          nitially the oxygen is blown into the molten metal. This reacts with
          the existing carbon in the bath forming bubbles of CO.
          <ul>
            <li>
              <span style="color: #000000">2C + O<sub>2</sub> = 2CO</span>
            </li>
            <li>
              <span style="color: #000000">2Fe + O<sub>2</sub> = 2FeO</span>
            </li>
          </ul>
        </li>
        <li class="product-items-list my-4">
          As the iron is oxidized, carbon is injected into the slag through
          cooled supersonic lances promoting the reduction of iron oxide. This
          stage generates CO and causes the iron to return to the molten bath:
          <br />C + FeO = CO + Fe
        </li>

        The injected coal must have a high percentage of fixed carbon (> 70%)
        and an adequate particle size (1/8″ to 250 microns) that allows it to
        dissolve in the slag without being prematurely consumed and travel
        through the injection system without complications. In addition to
        having low abrasive power to avoid damage to injection machinery.
      </div>
      <div v-if="selectedProduct == 'Calcined'">
        <div class="title font-bold text-3xl mb-5">Calcined Lime</div>
        a white crystalline oxide used in the production of calcium hydroxide.
        <div class="title font-bold text-3xl mb-5 mt-12">Calcined dolomite</div>
        Calcined dolomite is obtained by the calcination of dolomite, with a
        high content in calcium and magnesium carbonate CaMg(CO3)2 This product
        has mainly steel applications to improve slagging and to lengthen the
        lifespan of the refractory linings of ovens and ladles. Also, in
        metallurgy, chemistry, manufacture of refractory bricks and in
        stabilization in the process of manufacture of glass, improving its
        resistance to the aggression of gases and humidity. It can also be used
        in waste water and sludge treatment, as well as in fertilization and
        conditioning of terrains.
      </div>
      <div v-if="selectedProduct == 'fluorspar'">
        <div class="title font-bold text-3xl mb-5">Fluorspar</div>
        Fluorspar is used directly or indirectly to manufacture products such as
        aluminum, gasoline, insulating foams, refrigerants, steel, and uranium
        fuel. Byproduct fluorosilicic acid production from some phosphoric acid
        producers supplements fluorspar as a domestic source of fluorine, but is
        not included in fluorspar production or consumption calculations.
      </div>
      <div v-if="selectedProduct == 'graphite'">
        <div class="title font-bold text-3xl mb-5">Graphite Electrodes</div>
        archaically referred to as plumbago, is a crystalline form of the
        element carbon with its atoms arranged in a hexagonal structure. It
        occurs naturally in this form and is the most stable form of carbon
        under standard conditions. Under high pressures and temperatures it
        converts to diamond.

        <div
          class="overflow-x-auto my-12 bg-tableBg text-primary p-6 rounded-xl"
        >
          <table width="992">
            <tbody>
              <tr>
                <td class="text-center" colspan="6">
                  <strong>UHP FOR AC FURNITURE</strong>
                </td>
                <td class="text-center">
                  <strong>UHP FOR AC FURNITURE</strong>
                </td>
              </tr>
              <tr>
                <td rowspan="2">Parameter</td>
                <td rowspan="2">unit</td>
                <td class="text-center px-6">200-300 mm</td>
                <td class="text-center px-6">350-500 mm</td>
                <td class="text-center px-6">550 & 600 mm</td>
                <td class="text-center px-6">650 - 750 mm</td>
                <td class="text-center px-6">600 - 750 mm</td>
              </tr>
              <tr>
                <td class="text-center px-6">8 - 12</td>
                <td class="text-center px-6">14 - 20</td>
                <td class="text-center px-6">22 - 24</td>
                <td class="text-center px-6">26 - 30</td>
                <td class="text-center px-6">24 - 30</td>
              </tr>
              <tr>
                <td>Bulk Density</td>
                <td>gm/cm<sup>3</sup></td>
                <td class="text-center px-6">1.66 - 1.76</td>
                <td class="text-center px-6">1.65 - 1.75</td>
                <td class="text-center px-6">1.65 - 1.73</td>
                <td class="text-center px-6">1.66 - 1.74</td>
                <td class="text-center px-6">1.68 - 1.76</td>
              </tr>
              <tr>
                <td>specific resistance</td>
                <td>m W-m</td>
                <td class="text-center px-6">4.7 - 6.2</td>
                <td class="text-center px-6">4.5 - 6</td>
                <td class="text-center px-6">4.5 - 5.7</td>
                <td class="text-center px-6">4.5 - 5.3</td>
                <td class="text-center px-6">4.2 - 5.2</td>
              </tr>
              <tr>
                <td>Flexural Strength</td>
                <td>N/mm <sup>2</sup></td>
                <td class="text-center px-6">12 - 17</td>
                <td class="text-center px-6">9 - 14</td>
                <td class="text-center px-6">9 - 14</td>
                <td class="text-center px-6">9 - 14</td>
                <td class="text-center px-6">10 - 15</td>
              </tr>
              <tr>
                <td>Young's Modulus</td>
                <td>N/mm <sup>2</sup></td>
                <td class="text-center px-6">10 - 16</td>
                <td class="text-center px-6">9 - 15</td>
                <td class="text-center px-6">9 - 15</td>
                <td class="text-center px-6">9 - 13</td>
                <td class="text-center px-6">9 - 13</td>
              </tr>
              <tr>
                <td>Ash contents (MAX)</td>
                <td>%</td>
                <td class="text-center px-6">0.2</td>
                <td class="text-center px-6">0.2</td>
                <td class="text-center px-6">0.2</td>
                <td class="text-center px-6">0.2</td>
                <td class="text-center px-6">0.2</td>
              </tr>
              <tr>
                <td>C.T.E (longitudinal)</td>
                <td>CTE(X10 <sup>7</sup>/°C) @0-50 °C</td>
                <td class="text-center px-6">2.5 - 8.0</td>
                <td class="text-center px-6">2.5 - 8.0</td>
                <td class="text-center px-6">2.5 - 8.0</td>
                <td class="text-center px-6">2.0 - 5.0</td>
                <td class="text-center px-6">2.0 - 5.0</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="overflow-x-auto my-12 bg-tableBg text-primary p-6 rounded-xl"
        >
          <table width="992">
            <tbody>
              <tr>
                <td class="text-center" colspan="3"><strong>UHP-LF</strong></td>
              </tr>
              <tr>
                <td rowspan="2">Parameter</td>
                <td rowspan="2">unit</td>
                <td class="text-center px-6">300-500 mm</td>
              </tr>
              <tr>
                <td class="text-center px-6">12 - 20</td>
              </tr>
              <tr>
                <td>Bulk Density</td>
                <td>gm/cm<sup>3</sup></td>
                <td class="text-center px-6">1.65 - 1.75</td>
              </tr>
              <tr>
                <td>specific resistance</td>
                <td>m W-m</td>
                <td class="text-center px-6">5.0 - 6.2</td>
              </tr>
              <tr>
                <td>Flexural Strength</td>
                <td>N/mm <sup>2</sup></td>
                <td class="text-center px-6">9 - 14</td>
              </tr>
              <tr>
                <td>Young's Modulus</td>
                <td>N/mm <sup>2</sup></td>
                <td class="text-center px-6">8.5 - 14.5</td>
              </tr>
              <tr>
                <td>Ash contents (MAX)</td>
                <td>%</td>
                <td class="text-center px-6">0.2</td>
              </tr>
              <tr>
                <td>C.T.E (longitudinal)</td>
                <td>CTE(X10 <sup>7</sup>/°C) @0-50 °C</td>
                <td class="text-center px-6">3.0 - 8.5</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="overflow-x-auto my-12 bg-tableBg text-primary p-6 rounded-xl"
        >
          <table width="992">
            <tbody>
              <tr>
                <td class="text-center" colspan="4"><strong>HP</strong></td>
              </tr>
              <tr>
                <td rowspan="2">Parameter</td>
                <td rowspan="2">unit</td>
                <td class="text-center px-6">150-300 mm</td>
                <td class="text-center px-6">350-500 mm</td>
              </tr>
              <tr>
                <td class="text-center px-6">6 - 12</td>
                <td class="text-center px-6">14 - 20</td>
              </tr>
              <tr>
                <td>Bulk Density</td>
                <td>gm/cm<sup>3</sup></td>
                <td class="text-center px-6">1.66 - 1.75</td>
                <td class="text-center px-6">1.65 - 1.75</td>
              </tr>
              <tr>
                <td>specific resistance</td>
                <td>m W-m</td>
                <td class="text-center px-6">5.5 - 7.5</td>
                <td class="text-center px-6">5.5 - 7.5</td>
              </tr>
              <tr>
                <td>Flexural Strength</td>
                <td>N/mm <sup>2</sup></td>
                <td class="text-center px-6">12 - 17</td>
                <td class="text-center px-6">9 - 15</td>
              </tr>
              <tr>
                <td>Young's Modulus</td>
                <td>N/mm <sup>2</sup></td>
                <td class="text-center px-6">8 - 14</td>
                <td class="text-center px-6">7 - 13</td>
              </tr>
              <tr>
                <td>Ash contents (MAX)</td>
                <td>%</td>
                <td class="text-center px-6">0.5</td>
                <td class="text-center px-6">0.5</td>
              </tr>
              <tr>
                <td>C.T.E (longitudinal)</td>
                <td>CTE(X10 <sup>7</sup>/°C) @0-50 °C</td>
                <td class="text-center px-6">5.0 - 12.0</td>
                <td class="text-center px-6">5.0 - 12.0</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="overflow-x-auto my-12 bg-tableBg text-primary p-6 rounded-xl"
        >
          <table width="992">
            <tbody>
              <tr>
                <td class="text-center" colspan="4"><strong>RP</strong></td>
              </tr>
              <tr>
                <td rowspan="2">Parameter</td>
                <td rowspan="2">unit</td>
                <td class="text-center px-6">150-300 mm</td>
                <td class="text-center px-6">350-500 mm</td>
              </tr>
              <tr>
                <td class="text-center px-6">6 - 12</td>
                <td class="text-center px-6">14 - 20</td>
              </tr>
              <tr>
                <td>Bulk Density</td>
                <td>gm/cm<sup>3</sup></td>
                <td class="text-center px-6">1.56 - 1.65</td>
                <td class="text-center px-6">1.56 - 1.65</td>
              </tr>
              <tr>
                <td>specific resistance</td>
                <td>m W-m</td>
                <td class="text-center px-6">6.0 - 8.0</td>
                <td class="text-center px-6">6.5 - 8.5</td>
              </tr>
              <tr>
                <td>Flexural Strength</td>
                <td>N/mm <sup>2</sup></td>
                <td class="text-center px-6">9 - 12</td>
                <td class="text-center px-6">7 - 11</td>
              </tr>
              <tr>
                <td>Young's Modulus</td>
                <td>N/mm <sup>2</sup></td>
                <td class="text-center px-6">8 - 10</td>
                <td class="text-center px-6">6 - 10</td>
              </tr>
              <tr>
                <td>Ash contents (MAX)</td>
                <td>%</td>
                <td class="text-center px-6">0.5</td>
                <td class="text-center px-6">0.5</td>
              </tr>
              <tr>
                <td>C.T.E (longitudinal)</td>
                <td>CTE(X10 <sup>7</sup>/°C) @0-50 °C</td>
                <td class="text-center px-6">0.5 - 1.2</td>
                <td class="text-center px-6">0.5 - 1.2</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> -->
    </div>
    <button class="btn-back mt-12 text-primary" @click="$router.back()">
      <div class="flex items-center">
        <img src="/static/images/arrow.svg" class="back-arrow" />

        {{ content.backToServices }}
      </div>
    </button>
  </div>

  <TheFooter />
</template>

<script>
import SkeletonLoading from "/src/components/ui/ServiceDetailSkeleton.vue";
// import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore from "swiper";
import { Pagination } from "swiper";
import { EffectFade } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/swiper.min.css";
SwiperCore.use([Pagination, EffectFade]);

export default {
  data() {
    return {
      swiperEvent: null,
      breakpoints: {
        // when window width is >= 320px
        20: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        // when window width is >= 480px
        770: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        // when window width is >= 640px
        980: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      },
      selectedProduct: "finished",
      slideImgs: {
        Billets: [
          { name: "Steel Billet", image: "/static/images/slide/slab-4.jpeg" },
          // { name: "Steel Slab", image: "/static/images/slide/slab-1.png" },
          { name: "Steel Slab", image: "/static/images/slide/slab.png" },
          // { name: "Steel Slab", image: "/static/images/slide/slab-6.jpg" },
          // { name: "Steel Slab", image: "/static/images/slide/slab-1.png" },
        ],
        finished: [
          // { name: "Steel Rebar", image: "/static/images/slide/billet-5.jpg" },
          { name: "Steel Rebar", image: "/static/images/slide/Rebar.jpg" },
          { name: "Wire rod", image: "/static/images/slide/wire-2.jpg" },
          // { name: "Wire rod", image: "/static/images/slide/wire-3.jpg" },
          // { name: "Wire rod", image: "/static/images/slide/wire-4.jpg" },
          // { name: "Wire rod", image: "/static/images/slide/wire.jpg" },
        ],
        MainRawMaterials: [
          { name: "DRI", image: "/static/images/slide/DRI-1.jpg" },
          { name: "HBI", image: "/static/images/slide/HBI-1.png" },
        ],
        FerroAlloys: [
          {
            name: "Ferro Silicon",
            image: "/static/images/slide/Ferrosilicon-2.jpg",
          },

          {
            name: "Ferro Manganese",
            image: "/static/images/slide/ferro-manganese.jpeg",
          },
          {
            name: "Ferrosilicon Manganese",
            image: "/static/images/slide/ferro-silico-manganese.jpg",
          },
          {
            name: "Ferro Chrome",
            image: "/static/images/slide/Fe-Cr.jpg",
          },
        ],

        Coal: [{ name: "Carbon", image: "/static/images/slide/coal-2.jpg" }],
        Refractories: [
          // {
          //   name: "Refractories",
          //   image: "/static/images/slide/Refractories.jpg",
          // },
          {
            name: "Magnesite bricks for steel making furnace",
            // image: "/static/images/slide/Refractories-2.jpg",
            image: "/static/images/slide/Magnesite-Carbon-Bricks-2.jpg",
          },
        ],
        Calcined: [
          { name: "Calcined", image: "/static/images/slide/Calcined.jpg" },
          // { name: "Calcined", image: "/static/images/slide/Calcined-5.jpg" },
        ],
        fluorspar: [
          { name: "Fluorspar", image: "/static/images/slide/Fluorspar-6.jpg" },
        ],
        graphite: [
          {
            name: "Graphite Electrodes ",
            image: "/static/images/slide/ge-2.png",
          },
        ],
      },
    };
  },
  props: ["service"],
  inject: ["content"],
  created() {
    document.getElementById("main-header").classList.add("bg-primary");
  },
  beforeUnmount() {
    document.getElementById("main-header").classList.remove("bg-primary");
    this.$store.dispatch("services/clearServiceDetail");
  },
  methods: {
    async loadData() {
      await this.$store.dispatch("services/getServicesDetail", this.service);
      await this.$store.dispatch("products/getProducts");
      await this.$store.dispatch("productType/getProductType");
    },
    setThumbsSwiper(swiper) {
      this.swiperEvent = swiper;
    },
    slideTo(index) {
      this.swiperEvent.slideTo(index + 1, 2000);
    },
    selectProduct(productTitle) {
      this.$router.push({
        name: "ProductType",
        params: { title: productTitle },
      });
    },
    // selectProduct(product) {
    //   this.selectedProduct = "FerroAlloys";
    //   setTimeout(() => {
    //     this.selectedProduct = product;
    //   }, 1);
    // },
  },
  components: {
    SkeletonLoading,
    // Swiper,
    // SwiperSlide,
  },

  computed: {
    servicesDetail() {
      return this.$store.getters["services/servicesDetail"];
    },
    products() {
      return this.$store.getters["products/products"];
    },
    productType() {
      return this.$store.getters["productType/productType"];
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style scoped>
.swiper-container {
  border-radius: 10px;
}
.ProductItem {
  transition: transform 200ms ease-in-out;
  max-height: 90%;
}

.ProductItem:hover {
  transform: scale(1.03);
}
.ProductImage {
  width: 100%;
  object-fit: cover;
  transition: transform 400ms ease-in-out;
}

.ProductImage:hover {
  transform: scale(1.1);
}
</style>
