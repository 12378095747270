<template>
  <SkeletonLoading
    v-if="!productsDetail || Object.keys(productsDetail).length === 0"
  />
  <div class="my-12 lg:my-24 grid grid-cols-2 layout-padding order-last" v-else>
    <div class="col-span-2 lg:col-span-1 lg:hidden flex">
      <div
        class="service-detail-image"
        :style="
          productsDetail?.image
            ? `background: url(${productsDetail.image})`
            : ''
        "
      >
        <div class="img-overlay-two p-8">
          <div class="home-values-border"></div>
        </div>
      </div>
    </div>
    <div
      class="col-span-2 lg:col-span-1 flex flex-col justify-center lg:mb-0 my-12"
    >
      <div class="relative">
        <div class="page-title mb-6 lg:mb-16">
          {{
            productsDetail?.title
              ? productsDetail.title.charAt(0).toUpperCase() +
                productsDetail.title.slice(1)
              : ""
          }}
        </div>
        <div class="mb-6 lg:mb-16">
          {{
            productsDetail?.description
              ? productsDetail.description.charAt(0).toUpperCase() +
                productsDetail.description.slice(1)
              : ""
          }}
        </div>
        <div class="services-page-underlay-two uppercase">
          {{ productsDetail?.title || "" }}
        </div>
      </div>
      <div class="service-detail-text"></div>
      <button class="btn-back mt-12 text-primary" @click="$router.back()">
        <div class="flex items-center">
          <img src="/static/images/arrow.svg" class="back-arrow" />
          Back
        </div>
      </button>
    </div>
    <div class="col-span-2 lg:col-span-1 lg:flex hidden">
      <div
        class="service-detail-image"
        :style="
          productsDetail?.image
            ? `background: url(${productsDetail.image})`
            : ''
        "
      >
        <div class="img-overlay-two p-8">
          <div class="home-values-border"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="border-t border-tableBg mb-10"></div>
  <div class="page-title -mb-10 layout-padding">
    {{
      productsDetail?.title
        ? productsDetail.title.charAt(0).toUpperCase() +
          productsDetail.title.slice(1)
        : ""
    }}
    products
  </div>
  <div class="my-12 lg:my-24 flex flex-wrap layout-padding">
    <div class="w-full flex justify-center mt-10">
      <ul
        class="flex flex-wrap w-full gap-x-2"
        :class="
          filteredProducts.length === 1 ? 'justify-start' : 'justify-center'
        "
      >
        <li
          v-for="product in filteredProducts"
          :key="product.id"
          class="ProductList rounded-md flex-none md:w-1/4 lg:w-1/5 w-1/3"
        >
          <div
            class="ProductItem relative rounded-md border w-full border-tableBg shadow-md flex flex-col gap-5 items-center"
            style="height: 300px; overflow: hidden;"
          >
            <img
              class="ProductImage rounded-md w-full h-full object-cover"
              :src="product?.image"
              alt=""
            />
            <span
              class="bg-black bg-opacity-50 text-lg text-white font-semibold absolute bottom-0 left-0 text-start py-2 px-2 w-1/2"
            >
              {{ product?.title || "" }}
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <TheFooter />
</template>

<script>
import SkeletonLoading from "../../../components/ui/ServiceDetailSkeleton.vue";
export default {
  async created() {
    document.getElementById("main-header").classList.add("bg-primary");
    await this.loadData();
  },
  beforeUnmount() {
    document.getElementById("main-header").classList.remove("bg-primary");
    this.$store.dispatch("products/clearProductsDetail");
  },
  methods: {
    async loadData() {
      await this.$store.dispatch("products/getProducts");
      await this.$store.dispatch("productType/getProductType");
      await this.$store.dispatch("products/getProductsDetail", 1);
    },
  },
  components: {
    SkeletonLoading,
  },
  computed: {
    title() {
      return this.$route.params.title || "";
    },
    productsDetail() {
      const products = this.$store.getters["products/products"] || [];
      const matchingProduct = products.find(
        (product) => product.title.toLowerCase() === this.title.toLowerCase()
      );
      return matchingProduct || {};
    },
    products() {
      return this.$store.getters["products/products"] || [];
    },
    filteredProducts() {
      const products = this.$store.getters["productType/productType"] || [];
      return products.filter(
        (product) => product.type.toLowerCase() === this.title.toLowerCase()
      );
    },
  },
};
</script>

<style scoped>
.swiper-container {
  border-radius: 10px;
}
.ProductItem {
  transition: transform 300ms ease-in-out, z-index 0ms 300ms;
  max-height: 90%;
  z-index: 1;
}

.ProductItem:hover {
  transform: scale(1);
  z-index: 99;
  transition: transform 300ms ease-in-out, z-index 0ms;
}
.ProductImage {
  width: 100%;
  object-fit: cover;
  transition: transform 400ms ease-in-out;
}
.ProductList {
  flex-grow: 0;
  transition: flex-grow 0.3s ease;
  max-width: 40%;
  transform: scale(0.98);
  transition: transform 0.7s ease, flex-grow 0.7s ease, width 0.5s;
}
.ProductList:hover {
  flex-grow: 1;
  transform: scale(1);
}
.ProductImage:hover {
  transform: scale(1.05);
}
@media only screen and (min-width: 1800px) {
  .ProductItem:hover {
    transform: scale(1.01);
    z-index: 99;
    transition: transform 300ms ease-in-out, z-index 0ms;
  }
}
@media only screen and (max-width: 1023px) {
  .ProductList {
    flex-grow: 0;
    transition: flex-grow 0.3s ease;
    max-width: 50%;
    transform: scale(0.98);
    transition: transform 0.5s ease, flex-grow 0.5s ease;
  }
}
@media only screen and (max-width: 400px) {
  .ProductList {
    flex-grow: 0;
    transition: flex-grow 0.3s ease;
    max-width: 60%;
    transform: scale(0.98);
    transition: transform 0.5s ease, flex-grow 0.5s ease;
  }
}
</style>
