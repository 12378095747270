<template>
  <div class="relative">
    <swiper
      ref="swiper"
      class="swiper-container"
      :effect="'fade'"
      :slides-per-view="1"
      :space-between="0"
      :allowTouchMove="screenWidth < 880 ? true : false"
      @swiper="setThumbsSwiper"
      @beforeInit="setTouch"
      @slideChange="onSlideChange"
      @click="autoplay = false"
    >
      <swiper-slide v-for="slide in slideData.slice(0, 3)" :key="slide.id"
        ><HomeSlide
          :title="slide.title"
          :subtitle="slide.subtitle"
          :img="slide.image"
      /></swiper-slide>
    </swiper>
    <div
      class="slide-navigation layout-padding"
      :class="{ hidden: screenWidth < 880 }"
    >
      <div
        class="card"
        v-for="(slide, index) in slideData.slice(0, 3)"
        :key="index"
        @click="changeSlide(index)"
        :class="{ 'active-slide': currentSlide == index }"
      >
        <div class="title">{{ index + 1 }}. {{ slide.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeSlide from "/src/components/ui/HomeSlide.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore from "swiper";
import { EffectFade } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
SwiperCore.use([EffectFade]);

export default {
  props: ["slideData"],
  data() {
    return {
      autoplay: true,
      currentSlide: 0,
      swiperEvent: null,
      allowTouch: false,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    HomeSlide,
  },
  computed: {
    screenWidth() {
      return screen.width;
    },
  },

  methods: {
    setThumbsSwiper(swiper) {
      this.swiperEvent = swiper;
    },
    setTouch() {
      if (this.screenWidth < 880) {
        this.allowTouch = true;
        this.autoplay = false;
      }
    },
    changeSlide(index) {
      this.autoplay = false;
      this.swiperEvent.slideTo(index, 2000);
      this.currentSlide = index;
    },
    onSlideChange(swiper) {
      this.currentSlide = swiper.activeIndex;
    },
  },
  watch: {
    currentSlide() {
      if (this.autoplay == true) {
        setTimeout(() => {
          this.currentSlide = this.currentSlide + 1;
          if (this.currentSlide > 2) this.currentSlide = 0;
          this.swiperEvent.slideTo(this.currentSlide, 2000);
        }, 5000);
      }
    },
  },
  mounted() {
    if (this.autoplay == true) {
      setTimeout(() => {
        this.currentSlide = this.currentSlide + 1;
        this.swiperEvent.slideTo(this.currentSlide, 2000);
      }, 5000);
    }
  },
};
</script>

<style scoped>
.swiper-container {
  margin-top: -110px;
  position: relative;
}
.swiper-wrapper.disabled {
  transform: translate3d(0px, 0, 0) !important;
}
.swiper-pagination.disabled {
  display: none;
}
</style>