export default {
  getters: {
    homeSlider(state) {
      return state.homeSlider;
    },
    home(state) {
      return state.home;
    },
  },
};
