import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import TheFooter from "/src/components/Layout/TheFooter.vue";
import Loading from "/src/components/ui/Loading.vue";
import "./assets/tailwind.css";
const app = createApp(App);
app.component("TheFooter", TheFooter);
app.component("Loading", Loading);
app.use(store);
app.use(router);
app.mount("#app");
