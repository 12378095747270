import http from "/src/httpRequest.js";
export default {
  actions: {
    async getProducts(context) {
      const response = await http.get("products");
      context.commit("setProducts", response.data);
    },
    async getProductsDetail(context, payload) {
      const response = await http.get(`products/${payload}`);
      context.commit("setProductsDetail", response.data);
    },
    async clearProductsDetail(context) {
      context.commit("clearProductsDetail", null);
    },
  },
};
