//let originURL = "http://	api.brightflameco.com/api/";
let originURL = "https://api.brightflameco.com/api/";
// let postURL = "https://calm-falls-29246.herokuapp.com/";

export default {
  get: async function(url, lang) {
    let requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Origin: originURL,
      },
    };
    let response = await send(url, requestOptions, lang);
    return response;
  },
};
async function send(url, requestOptions) {
  let response = await fetch(originURL + url, requestOptions).then((response) =>
    response.json()
  );
  return response;
}
