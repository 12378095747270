<template>
  <Loading v-if="loading" />

  <TheHeader />
  <router-view />
</template>
<script>
import TheHeader from "/src/components/Layout/TheHeader.vue";
import content from "./Languages.js";

export default {
  data() {
    return {
      loading: false,
    };
  },
  components: {
    TheHeader,
  },
  provide: {
    content: content[localStorage.getItem("lang") ?? "en"],
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("home/getHomeSlider");
    await this.$store.dispatch("services/getServices");
    await this.$store.dispatch("whoWeAre/getWhoWeAre");
    await this.$store.dispatch("operations/getOperations");
    await this.$store.dispatch("contact/getContact");
    this.loading = false;
  },
};
</script>


