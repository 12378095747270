<template>
  <div class="home-serivces layout-padding mt-24 md:mt-48">
    <div class="grid grid-cols-2 gap-x-24 lg:gap-20 gap-y-10">
      <div class="col-span-2 lg:col-span-1 flex justify-between flex-col">
        <div>
          <div class="home-service-title">{{ services.title }}</div>
          <div class="home-service-subtitle my-8">
            {{ services.subtitle }}
          </div>
          <div class="home-service-content">
            {{ services.description }}
          </div>
        </div>
        <div class="flex flex-wrap mt-8">
          <button
            class="btn-fill"
            @click="$router.push('/services')"
            style="margin-inline-end: 10px"
          >
            <div class="flex items-center">
              {{ content.moreInfo }}
              <img src="/static/images/arrow-w.svg" class="btn-arrow" />
            </div>
          </button>
          <button class="btn-outline">
            <a
              class="flex items-center"
              :href="services.catalogue"
              download="catalogue.pdf"
              target="_blank"
            >
              {{ content.catalogue }}
              <img src="/static/images/arrow.svg" class="btn-arrow" />
            </a>
          </button>
        </div>
      </div>
      <!-- <div class="col-span-2 lg:col-span-1">
        <div
          class="home-service-main-img"
          :style="`background: url(${services.image})`"
        >
          <div class="img-overlay"></div>
        </div>
      </div> -->

      <router-link
        :to="`/services/${service.id}`"
        v-if="service"
        class="col-span-2 lg:col-span-1 service-card"
      >
        <div
          class="home-service-img"
          :style="`background: url(${service.image})`"
        >
          <div class="img-overlay-one service-section flex items-end p-8">
            <div class="text-4xl   text-white">{{ service.title }}</div>
          </div>
        </div>
        <!-- <div class="service-section mt-6">
          <div class="number">{{ 1 }}</div>
          <div class="flex flex-col justify-center">
            <div class="title">{{ service.title }}</div>
            <p class="content mt-2">
              {{
                service.description.toString().substring(0, this.stringLength)
              }}..
              <span
                class="inline-btn"
                @click="$router.push(`/services/${service.id}`)"
                >Read More</span
              >
            </p>
          </div>
        </div> -->
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["services"],
  inject: ["content"],
  computed: {
    stringLength() {
      let content;
      if (screen.width > 1000) {
        content = Math.round(screen.width / 15);
      } else if (screen.width < 1000 && screen.width > 600) {
        content = Math.round(screen.width / 7);
      } else if (screen.width < 600) {
        content = Math.round(screen.width / 8);
      }
      return content;
    },
    serviceContent() {
      let content = `  Sed ut perspiciatis unde omnis iste natus error sit volup
              spiciatis unde omnis iste atis unde omnis iste natus error sit
              volup spici unde omnis iste natus error sit volup spiciatis unde
              omnis iste`;
      return content.toString().substring(0, this.stringLength);
    },
    service() {
      return this.services?.services.find((service) => service.id === 1);
    },
  },
};
</script>
