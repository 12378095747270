<template>
  <Loading v-if="!operations" />
  <div v-else>
    <div class="my-12 xl:my-24 layout-padding grid grid-cols-2 gap-20">
      <div class="col-span-2 lg:col-span-1">
        <div
          class="operate-banner-image"
          :style="`background: url(${operations[0].image})`"
        >
          <div class="contact-rectangles">
            <div class="rectangle-shape"></div>
            <div class="rectangle-shape mx-4"></div>
            <div class="rectangle-shape"></div>
          </div>
          <div class="img-overlay-one"><div class="img-overlay-two"></div></div>
        </div>
      </div>
      <div
        class="col-span-2 lg:col-span-1 flex flex-col justify-center relative"
      >
        <div class="operate-title">{{ content.whereWeOperate }}</div>
        <div class="operate-main-text mt-12">
          {{ operations[0]?.subtitle }}
        </div>
      </div>
    </div>
    <div
      class="
        operate-countries
        grid grid-cols-4
        gap-8
        layout-padding
        mb-12
        xl:mb-24
      "
    >
      <div
        class="country-card col-span-4 xl:col-span-1 md:col-span-2"
        v-for="location in operations[0]?.locations"
        :key="location.id"
      >
        <div class="title mb-6">{{ location?.country }}</div>

        <div class="text">
          {{ location?.description }}
        </div>
      </div>
    </div>
  </div>
  <TheFooter />
</template>


<script>
export default {
  inject: ["content"],
  created() {
    document.getElementById("main-header").classList.add("bg-primary");
  },
  beforeUnmount() {
    document.getElementById("main-header").classList.remove("bg-primary");
  },
  methods: {
    async loadData() {
      await this.$store.dispatch("operations/getOperations");
    },
  },
  computed: {
    operations() {
      return this.$store.getters["operations/operations"];
    },
  },
  mounted() {
    if (!this.operations) this.loadData();
  },
};
</script>