import { createRouter, createWebHistory } from "vue-router";
import Home from "/src/views/Home.vue";
import About from "/src/views/About.vue";
import Contact from "/src/views/Contact.vue";
import Services from "/src/views/Services.vue";
import ServiceDetail from "/src/views/Details/ServiceDetail.vue";
import Operate from "/src/views/Operate.vue";
import NotFound from "/src/NotFound.vue";
import Search from "/src/views/Search.vue";
import ProductType from "/src/views/Details/ProductType";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/search",
    name: "search",
    component: Search,
  },
  {
    path: "/services",
    name: "services",
    component: Services,
    props: true,
  },
  {
    path: "/services/:service",
    component: ServiceDetail,
    props: true,
  },
  {
    path: "/services/:service/:title",
    name: "ProductType",
    component: ProductType,
  },
  {
    path: "/:notFound(.*)",
    component: NotFound,
  },
  {
    path: "/operate",
    name: "operate",
    component: Operate,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(from, to, savedPosition) {
    // @M: we need to configure this based on routes
    if (savedPosition) {
      return savedPosition;
    }

    return { top: 0, left: 0 };
  },
});

export default router;
