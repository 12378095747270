<template>
  <Loading v-if="!whoWeAre" />
  <div v-else>
    <div
      class="about-banner-image"
      style="background: url(/static/images/about.jpg)"
    >
      <div class="about-banner-overlay"></div>
      <div class="home-values-rectangles">
        <div class="rectangle-shape"></div>
        <div class="rectangle-shape mx-4"></div>
        <div class="rectangle-shape"></div>
      </div>
    </div>
    <div class="about-us-section layout-padding mt-32">
      <div class="aboutus-underlay uppercase">{{ content.aboutUs }}</div>
      <div class="about-title">{{ content.whoWeAre }}</div>
      <p class="about-text mt-16">
        {{ whoWeAre[0].description }}
      </p>
    </div>
    <div
      class="
        approach-section
        grid grid-cols-2
        mt-28
        layout-padding
        mb-32
        gap-20
      "
    >
      <div class="col-span-2 lg:col-span-1">
        <div
          class="approach-image"
          :style="`background: url(${whoWeAre[0].image})`"
        >
          <div class="img-overlay-two"></div>
        </div>
      </div>
      <div
        class="col-span-2 lg:col-span-1 flex flex-col justify-center lg:mt-0"
      >
        <div class="approach-title">{{ content.ourApproach }}</div>
        <div class="approach-content mt-11">
          {{ whoWeAre[0].approach }}
        </div>
        <div class="vision-title mt-11">{{ content.ourVision }}</div>
        <div class="vision-content mt-4">
          {{ whoWeAre[0].vision }}
        </div>
        <div class="mission-title mt-11">{{ content.ourMission }}</div>
        <div class="mission-content mt-4">
          {{ whoWeAre[0].mission }}
        </div>
      </div>
    </div>
  </div>
  <TheFooter :withContact="true" />
</template>
<script>
export default {
  inject: ["content"],
  methods: {
    async loadData() {
      if (!this.whoWeAre) await this.$store.dispatch("whoWeAre/getWhoWeAre");
    },
  },
  computed: {
    whoWeAre() {
      return this.$store.getters["whoWeAre/whoWeAre"];
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>