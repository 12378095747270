<template>
  <Loading v-if="!contact" />

  <div
    class="mt-12 xl:mt-24 layout-padding grid grid-cols-2 gap-20 mb-60"
    v-else
  >
    <div class="col-span-2 lg:col-span-1">
      <div
        class="contact-banner-image"
        style="background: url(/static/images/contact.jpg)"
      >
        <div class="contact-rectangles">
          <div class="rectangle-shape"></div>
          <div class="rectangle-shape mx-4"></div>
          <div class="rectangle-shape"></div>
        </div>
        <div class="img-overlay-one"><div class="img-overlay-two"></div></div>
      </div>
    </div>
    <div class="col-span-2 lg:col-span-1 flex flex-col justify-center relative">
      <div class="contact-underlay uppercase">{{ content.contactUs }}</div>
      <div class="contact-title">{{ content.getInTouch }}</div>
      <div class="contact-info mt-24">
        <div>
          {{ content.callUsOn }}
          <span class="phone"> {{ contact[0].phone }}</span>
        </div>
        <div>
          {{ content.emailUsOn }}
          <span class="email"> {{ contact[0].email }} </span>
        </div>
        <div>
          {{ content.weAreHere }}
          <span class="address"> {{ contact[0].address }} </span>
        </div>
        <div>
          {{ content.followUsOn
          }}<span class="social">
            <a
              href="https://www.linkedin.com/in/bright-flame-66b248226/"
              target="_blank"
            >
              LinkedIn</a
            >
          </span>
        </div>
      </div>
    </div>
  </div>
  <TheFooter />
</template>
<script>
export default {
  inject: ["content"],
  created() {
    document.getElementById("main-header").classList.add("bg-primary");
  },
  beforeUnmount() {
    document.getElementById("main-header").classList.remove("bg-primary");
  },
  methods: {
    async loadData() {
      if (!this.contact) await this.$store.dispatch("contact/getContact");
    },
  },
  computed: {
    contact() {
      return this.$store.getters["contact/contacts"];
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>