<template>
  <Loading v-if="!HomeSlider && !Home" />

  <div v-else>
    <MainSlide :slideData="HomeSlider" />
    <Services :services="Home.WhatWeDo" /><OurValues
      :values="Home.OurValues"
    /><Operate :operate="Home.WhereWeOperate" />
  </div>
  <TheFooter :withContact="true" />
</template>

<script>
import MainSlide from "/src/components/Home/MainSlide.vue";
import Services from "/src/components/Home/Services.vue";
import OurValues from "/src/components/Home/OurValues.vue";
import Operate from "/src/components/Home/Operate.vue";

export default {
  components: {
    MainSlide,
    Services,
    OurValues,
    Operate,
  },
  methods: {
    async loadData() {
      await this.$store.dispatch("home/getHomeSlider");
    },
  },
  computed: {
    HomeSlider() {
      return this.$store.getters["home/homeSlider"];
    },
    Home() {
      return this.$store.getters["home/home"];
    },
  },
  mounted() {
    if (!this.Home) this.loadData();
  },
};
</script>