export default {
  mutations: {
    setProductType(state, payload) {
      state.productType = payload;
    },
    setProductTypeDetail(state, payload) {
      state.productTypeDetail = payload;
    },
    clearProductTypeDetail(state, payload) {
      state.productTypeDetail = payload;
    },
  },
};
